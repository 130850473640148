import { render, staticRenderFns } from "./staffBaoyou.vue?vue&type=template&id=78e2c8c1&scoped=true&"
import script from "./staffBaoyou.vue?vue&type=script&lang=js&"
export * from "./staffBaoyou.vue?vue&type=script&lang=js&"
import style0 from "./staffBaoyou.vue?vue&type=style&index=0&id=78e2c8c1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78e2c8c1",
  null
  
)

export default component.exports