<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" label-suffix="：" :inline="true" size="mini" :model="search">


          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select v-model="search.shop_id" clearable remote filterable :remote-method="remoteMethod"
              @change="changeShop" placeholder="请输入" class="w120">
              <el-option v-for="item in options.shop_id" :key="item.shopId" :label="item.shopName"
                :value="item.shopId" />
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-switch v-model="value1" active-text="当年 (默认显示当年)" inactive-text="累计" @change="changeCJTime">
            </el-switch>
          </el-form-item>

        </el-form>
      </el-col>

      <!-- <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col> -->
    </el-row>
    <div class="accountCon">

      <!--列表-->
      <el-row>
        <div class="btnBox">
          <el-button type="primary" class="mb10" size="mini" v-if="!userInfo.shop_id"
            @click="getExport()">导出数据</el-button>
        </div>
        <el-col :span="24">
          <el-table :data="stafflist" size="small" border v-loading="loading" :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass" @selection-change="handleSelectionChange">
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="员工姓名" width="120" prop="name" fixed="left" />
            <ElTableColumn label="性别" width="60" prop="sex" fixed="left" />
            <ElTableColumn label="市场" width="120" prop="marketName" fixed="left" />
            <ElTableColumn label="门店" width="120" prop="shopName" fixed="left" />
            <ElTableColumn label="职位" width="120" prop="positionName" fixed="left" />
            <ElTableColumn label="成交顾客数" prop="cj_customer" fixed="left" />
            <ElTableColumn label="保有顾客数">
              <template slot-scope="{ row }">
                <div @click="clickCJBtn('保有顾客', row, '', '')">
                  {{ row.baoyou_customer }}</div>
              </template>
            </ElTableColumn>
			<ElTableColumn label="保有率" prop="baoyou_rate" />
            <ElTableColumn label="流失顾客数">
              <template slot-scope="{ row }">
                <div @click="clickCJBtn('流失顾客', row, '', '')">
                  {{ row.lost_customer }}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="流失预警数">
              <template slot-scope="{ row }">
                <div @click="clickCJBtn('流失预警', row, '', '')">
                  {{ row.warn_customer }}</div>
              </template>
            </ElTableColumn>

          </el-table>
          <!-- <pagination class="page tc mt10" :total="page.total" :page.sync="page.page" :limit.sync="page.limit"
            @pagination="getList" /> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

  <script>
  import { mapGetters } from "vuex";
  import pagination from "@/components/Pagination";
  import { getShopList,getMarketLsit } from "@/api/shop";
  import { getStaffList,getStaffBaoyou } from "@/api/staff";


  class Search {
    cj_type = '0'
  }
  class Options {
    sex = [
      { id: 0, name: "全部" },
      { id: 1, name: "男" },
      { id: 2, name: "女" }
    ];
    source_id = [];
    effective_entry = [
      { id: 0, name: "全部" },
      { id: 1, name: "是" },
      { id: 2, name: "否" }
    ];
    membership_leve = [];
    member_label = [];
    shop_id = [];
    market_id = [];
    nurse_id = [];
    member_classification = [];
    repair_progress = [];
    turn_status = [
      { id: 0, name: "全部" },
      { id: 1, name: "待处理" }
    ];
    scar_id = [];
    difficulty_level = [
      { id: 1, name: "1级" },
      { id: 2, name: "2级" },
      { id: 3, name: "3级" },
      { id: 4, name: "4级" }
    ];
    type = [
      {id: "1",name: "直营"},
      {id: "2",name: "加盟"},
      {id: "3",name: "直管"}
    ];
  }
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  export default {
    name: "ClientList",
    components: {
      pagination
    },
    data() {
      return {
        search: new Search(),
        options: new Options(),
        page: new Page(),
        stafflist: [],
        idList: "",
        loading: false,
        shop_id: '',
        value1: false,
      };
    },
    filters: {
      statusFilter(val) {
        return ["启用", "禁用"][+val];
      }
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    mounted() {
        if (typeof this.$route.query.search == "object") {
            this.search = this.$route.query.search;
            this.page = this.$route.query.page;
        }
        if(this.userInfo.shop_id){
          this.shop_id = this.userInfo.shop_id;
        }else{
          this.shop_id = '73782774834649';
        }
        //   this.getList();
        // this.userInfo.shop_id
        this.remoteMethodStaff(this.shop_id);
    },
    methods: {

      // 获取列表
      getList(option,exportType) {

        this.loading = true;
        if (option === "search") this.page = new Page();
        getStaffBaoyou({ staff_ids: this.stafflist,shop_id:this.shop_id,search:this.search }).then(res =>{
          console.log(res.data);

          this.stafflist = res.data;
          this.loading = false;
        })
      },
      // 搜索市场
      getMarket(e) {
        getMarketLsit({ page:1, limit:100000, marketName:e }).then(res => {
          console.log(res);
          this.options.market_id = res;
        })
      },

      // 搜索门店
      remoteMethod(e) {
        this.shopName = e;
        getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
          this.options.shop_id = res.list;
        });
      },

      // 搜索员工
      remoteMethodStaff(shop_id) {
          if (shop_id) {
              getStaffList({ page: 1, limit: 100000,shop_id:shop_id }).then(res => {
                console.log(res.list);
                this.stafflist = res.list;
                this.shop_id = shop_id;
                this.getList('search','');
              });
          }

      },

      // 导出
      getExport() {
        this.loading = true;

        getStaffBaoyou({ staff_ids: this.stafflist,shop_id:this.shop_id,export:1 }).then(res =>{
          console.log(res.data);
          window.open(res.data.url);
        })
      },
      // 改变表格选中状态
      handleSelectionChange(val) {
        this.idList = val.map(i => {
          return i.id;
        });
      },
      changeShop(e){
        this.remoteMethodStaff(e);
      },
      clickCJBtn(routerName, row, start_time, end_time) {

        const query = {
          shop_name:row.shopName,
          shop_id: row.shopId,
          nurse_id: row.id,
          start_time: start_time,
          end_time: end_time,
          type: "",
          switch:this.value1

        };



        // console.log(query);
        // return;

        this.$router.push({ name: routerName, query });
        // let route = this.$router.resolve({ name: routerName, query });
        // window.open(route.href, '_blank');
      },

      changeCJTime(e){
        if(e){
          // console.log('正确');
          this.search.cj_type = '1';
        }
        if(!e){
          // console.log('错误');
          this.search.cj_type = '0';
        }

        console.log(this.search);
        this.getList('search','');
        // return
        // this.getList('search');
      },
    }
  };
  </script>

  <style lang="scss" scoped>
  .mainColor {
    color: #2dc0a3;
  }
  .content {
    height: calc(100% - 56px);
    overflow-y: auto;
  }
  .bgW {
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .el-form-item {
    margin: 5px 20px 3px 0;
  }
  .accountCon {
    background: #fff;
    padding: 0px 10px 80px;
    .btnBox {
      display: flex;
      justify-content: space-between;
    }
  }
  </style>
